import React, {useEffect, useState} from "react";
import {API, Auth, graphqlOperation} from 'aws-amplify';
import {listLeaderboards, listEngagements} from '../../../graphql/queries';
import {Table} from 'react-bootstrap';
import moment from "moment";
import "./style.css";
import People from "../../../img/overview/people.png"


function Overview(props) {
    const  id  = props.data;
    const [thislength, setThislength] = useState(0);
    const [corrca, setCorrca] = useState(0);
    const [hiDu, setHiDu] = useState(0);
    const [loDu, setLoDu] = useState(0);
    const [hiscor, setHiscor] = useState(0);
    const [loscor, setLoscor] = useState(0);
    const [avera, setAvera] = useState(0);
    const [earliestt, setEarliestt] = useState("");
    const [latestt, setLatestt] = useState("");
    const [engagenumber, setEngagenumber] = useState("");
    useEffect(() => {
        fetchengagedata();
        fetchDas();
    }, []);
    const fetchDas = async () => {
        let dcounts = [];
        let scounts = [];
        try {
            const response = await API.graphql({
                query: listLeaderboards,
                variables: {
                    filter: {
                        "answerid": {
                            eq: id
                        }
                    },
                    limit:10000
                }
            })

            let filteredData = response.data.listLeaderboards.items;
            if (filteredData.length !== 0) {
                setThislength(filteredData.length)
                filteredData.forEach((data) => {
                    const listdata = data;
                    scounts.push(listdata)
                })
                scounts.sort((a, b) => {
                    return b.score - a.score;
                });
                scounts.forEach((e) => {
                    dcounts.push(e)
                });
                const result =  dcounts.map(item => {
                    const totalDuration = item.questions
                        ? item.questions.reduce((acc, question) => acc + question.duration, 0)
                        : 0;
                    return {
                        ...item,
                        dura: totalDuration,
                    };
                });
                const durations = result.map(item => item.dura);
                const hDu = Math.max(...durations);
                const convertToMinutes = (seconds) => {
                    const minutes = Math.floor(seconds / 60);
                    const remainingSeconds = seconds % 60;
                    return `${minutes}m ${remainingSeconds}s`; // Display in "minutes m seconds s" format
                };
                const hh = convertToMinutes(hDu)
                setHiDu(hh)
                const lDu = Math.min(...durations);
                const ll = convertToMinutes(lDu)
                setLoDu(ll);
                const scores = result.map(item => item.score);
                const hscore = Math.max(...scores);
                setHiscor(hscore);
                const lscore = Math.min(...scores);
                setLoscor(lscore);
                const calculateAverageScore = (data) => {
                    const validScores = data.filter(item => item.score !== undefined).map(item => item.score);
                    const totalScore = validScores.reduce((acc, score) => acc + score, 0);
                    const averageScore = validScores.length ? totalScore / validScores.length : 0;
                    const tt= Number.parseFloat(averageScore).toFixed(1)
                    return tt;
                };
                const averageScore = calculateAverageScore(filteredData);
                setAvera(averageScore)
                function findEarliestAndLatest(dates) {
                    let earliestTimein = null;
                    let latestTimeout = null;

                    dates.forEach((item) => {
                        const timein = moment(item.timerin, [
                            'dddd MMMM Do YYYY h:mm:ss a',
                            'MMMM Do YYYY h:mm:ss a',
                            'MM/DD/YYYY h:mm a',
                        ]);

                        const timeout = moment(item.timerout, [
                            'dddd MMMM Do YYYY h:mm:ss a',
                            'MMMM Do YYYY h:mm:ss a',
                            'MM/DD/YYYY h:mm a',
                        ]);

                        if (timein.isValid() && (earliestTimein === null || timein.isBefore(earliestTimein))) {
                            earliestTimein = timein;
                        }
                        if (timeout.isValid() && (latestTimeout === null || timeout.isAfter(latestTimeout))) {
                            latestTimeout = timeout;
                        }
                    });
                    return { earliestTimein, latestTimeout };
                }
                const { earliestTimein, latestTimeout } = findEarliestAndLatest(filteredData);
                setEarliestt(earliestTimein ? earliestTimein.format('MM/DD/YYYY hh:mm a') : 'N/A')
                setLatestt(latestTimeout ? latestTimeout.format('MM/DD/YYYY hh:mm a') : 'N/A');
                let correctCount = 0;
                let totalCount = 0;
                filteredData.forEach(item => {
                    if (item.questions) {
                        item.questions.forEach(question => {
                            if (question.correctanswer !== null) {
                                totalCount++;
                                if (question.correctanswer === "correct") {
                                    correctCount++;
                                }
                            }
                        });
                    }
                });
                const correctRate = totalCount > 0 ? (correctCount / totalCount) * 100 : 0;
                setCorrca(correctRate)
            }
        } catch (error) {
            console.error('Error fetching items:', error);
        }
    };
    async function fetchengagedata() {
        const peoples = [];
        const engages = [];
        try {
            const response = await API.graphql({
                query: listEngagements,
                variables: {
                    filter: {
                        "questionid": {
                            eq: id
                        }
                    },
                    limit: 10000
                }
            })
            const datas = response.data.listEngagements.items;
            if (datas.length > 0) {
                datas.forEach((data) => {
                    const listdata = data.people;
                    peoples.push(listdata)
                })
                const s = peoples.length;
                datas.forEach((data) => {
                    const listdata = data.engage;
                    engages.push(listdata)
                })
                const sum = engages.reduce((partialSum, a) => partialSum + a, 0);
                const numObj = (sum / s) * 100;
                const ea = Number.parseFloat(numObj).toFixed(1) + "%";
                setEngagenumber(ea);
            } else {
                const tt = "100%";
                setEngagenumber(tt);
            }

        } catch (err) {
            console.log(err)
        }
    }
    return (
        <>

            <div>
                <div className="peopleline">
                    <img src={People} alt="People" /> <p> : {thislength}</p></div>

                <Table striped bordered className="thistable">
                    <thead>
                    <tr>
                        <th>Lowest Score</th>
                        <td>{loscor}</td>
                    </tr>
                    <tr>
                        <th>Highest Score</th>
                        <td>{hiscor}</td>
                    </tr>
                    <tr>
                        <th>Average Score</th>
                        <td>{avera}</td>
                    </tr>
                    <tr>
                        <th>Response Rate</th>
                        <td>{engagenumber}</td>
                    </tr>
                    <tr>
                        <th>Correct Answer Rate</th>
                        <td>{`${corrca.toFixed(1)}%`}</td>
                    </tr>
                    <tr>
                        <th>Shortest Duration</th>
                        <td>{loDu}</td>
                    </tr>
                    <tr>
                        <th>Longest Duration</th>
                        <td>{hiDu}</td>
                    </tr>
                    <tr>
                        <th>Earliest Time In</th>
                        <td>{earliestt}</td>
                    </tr>
                    <tr>
                        <th>Latest Time Out</th>
                        <td>{latestt}</td>
                    </tr>
                    </thead>
                </Table>
            </div>
        </>
    )
}

export default Overview;
