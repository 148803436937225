import React from "react";
import "./css/foot.css";

function  Foott(){
    function gohide(){
        document.getElementById("gohidethis").style.display="none"
    }
    function gocon(){
        window.open("https://reachum.com/terms-and-conditions/", "_blank")
    }
    return (
        <>
            <div className="botts" id="gohidethis">
                <p>We use cookies to personalize your site experiences. Please review our <span className="ptext" onClick={gocon}> privacy policy</span>. Closing this panel indicates acknowledgement and acceptance of privacy policy. <span onClick={gohide} className="footclose">Close</span></p>
            </div>
            </>
    )
}


export default Foott;
