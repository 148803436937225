import React, {useState, createRef} from "react";
import CanvasDraw from "react-canvas-draw";
import { SketchPicker } from "react-color";
import "./style.css";
import { useClickAway } from "./useClickAway";

import classNames from "classnames";

const defaultProps = {
    loadTimeOffset: 5,
    lazyRadius: 0,
    brushRadius: 2,
    catenaryColor: "#0a0302",
    gridColor: "rgba(150,150,150,0.17)",
    hideGrid: true,
    canvasWidth: 550,
    canvasHeight: 250,
    disabled: false,
    imgSrc: "",
    saveData: "",
    immediateLoading: false,
    hideInterface: false
};

const colors = [
    "#B80000",
    "#DB3E00",
    "#FCCB00",
    "#008B02",
    "#006B76",
    "#1273DE",
    "#004DCF",
    "#5300EB",
    "#000000",
    "#EB9694",
    "#FAD0C3",
    "#FEF3BD",
    "#C1E1C5",
    "#BEDADC",
    "#C4DEF6",
    "#BED3F3",
    "#D4C4FB",
    "#CCCCCC",
];

const width = `${Math.ceil(colors.length / 2) * 32}px`;

function Draws({ onChildData }) {
    const canvasRef = createRef(null);
    const [brushColor, setBrushColor] = useState("#000000");
    const [showColor, setShowColor] = useState(false);
    const [saveData, setSaveData] = useState("");
    const getImg = () =>
        canvasRef.current.canvasContainer.children[1].toDataURL();

    const paletteRef = useClickAway(() => {
        setShowColor(false);
    });

    const handleClear = () => {
        canvasRef.current.clear();
        setSaveData("");
    };

    const handleCanvasChange = () => {
        const saveData = getImg();
        setSaveData(saveData);
        onChildData(saveData);
    };

    const props = {
        ...defaultProps,
        className: classNames("canvas"),
        onChange: handleCanvasChange,
        ref: canvasRef,
        brushColor,
        catenaryColor: brushColor,
    };

    return (
        <div className="draw">
            <div className="drawbutton-container">
                <div ref={paletteRef} className="drawpicker-container">
                    <button
                        className="palette"
                        onClick={() => {
                            setShowColor((s) => !s);
                        }}
                    >
            <span role="img" aria-label="">
              🎨
            </span>{" "}
                        color
                    </button>
                    {showColor && (
                        <div className="drawpickerpopper">
                            <SketchPicker
                                triangle={"hide"}
                                color={brushColor}
                                width={width}
                                onChangeComplete={(c) => setBrushColor(c.hex)}
                            />
                        </div>
                    )}
                </div>
                <button
                    className="undo"
                    onClick={() => {
                        canvasRef.current.undo();
                    }}
                >
          <span role="img" aria-label="">
            ↩️
          </span>{" "}
                    undo
                </button>
                <button className="clear" onClick={handleClear}>
          <span className="non-hover" role="img" aria-label="">
            🗑
          </span>{" "}
                    <span className="hover" role="img" aria-label="">
            ❌
          </span>{" "}
                    clear
                </button>
            </div>
            <div className="thisborder">
            <CanvasDraw {...props} />
            </div>
        </div>
    );
}

export default Draws
