import ConvertAPI from 'convertapi';
import { config } from '../../config'

const convertApi = new ConvertAPI(config.convert.secret);

export default async function convertPptxToJpeg(fileUrl) {
  const params = {
    'File': fileUrl,
    'ImageWidth': '1080'
  };
  const result = await convertApi.convert('jpg', params)
  return result.files.map(({ fileInfo }) => {
    const { FileId: fileId, Url: url } = fileInfo;
    return { name: fileId, url }});
}
