import React, {useEffect, useState} from "react";
import { API} from 'aws-amplify';
import {listLeaderboards} from '../../graphql/queries';
import {useParams} from "react-router-dom";
import {Table} from 'react-bootstrap';
import "../css/leaderboard.css";
import {Helmet} from "react-helmet";
import moment from "moment";
import {createLeaderboard, updateLeaderboard} from "../../graphql/mutations";

function Leaderboard() {
    const [myData, setMyData] = useState([]);
    const { id } = useParams();

    useEffect(() => {
        fetchDas();
    }, []);
    useEffect(() => {
        const vreachumnewerna = localStorage.getItem("vreachumnewername");
        const reachumnname = localStorage.getItem("vreachumneweremail");
        const starts = moment().format('dddd  MMMM Do YYYY  h:mm:ss a');
        (async () => {
            if (reachumnname) {
                try {
                    await API.graphql({
                        query: listLeaderboards,
                        variables: {
                            filter: {
                                "meetemail": {
                                    eq: reachumnname
                                },
                                "answerid": {
                                    eq: id
                                }
                            },
                            limit: 10000
                        }
                    }).then(datas => {
                        const plays = datas.data.listLeaderboards.items;
                        const playss = plays.length;
                        if (playss > 0) {
                            const thisid = plays[0].id;
                            sessionStorage.setItem("edureachumthisid", thisid)
                            const thisques = plays[0].questions;
                            if(thisques) {
                                const serializedArray = JSON.stringify(thisques);
                                sessionStorage.setItem("edureachumthisques", serializedArray)
                            }
                        }else{
                            const input = {
                                name: vreachumnewerna,
                                meetemail: reachumnname,
                                answerid: id,
                                score: 0,
                                thisgamescore: 0,
                                duration:0,
                                timerin:starts
                            }
                            const responsee = API.graphql({
                                query: createLeaderboard,
                                variables: {input}
                            });
                            const thistimerid = responsee.id;
                            sessionStorage.setItem("edureachumthisid", thistimerid)
                        }
                    })
                } catch (err) {
                    console.log(err)
                }
            }
        })()
    }, []);
    async function fetchDas() {
        const scounts = [];
        const dcounts=[];
        try {
            const response = await API.graphql({
                query: listLeaderboards,
                variables: {
                    filter: {
                        "answerid": {
                            eq: id
                        }
                    },
                    limit:10000
                    }
            })
            const datas = response.data.listLeaderboards.items;
            let filteredData = datas.filter(item => item.meetemail !== "instructor@ins.com");
            filteredData.forEach((data) => {
                const listdata = data;
                scounts.push(listdata)
            })
            scounts.sort((a, b) => {
                return b.score - a.score;
            });
            scounts.forEach((e) => {
                dcounts.push(e)
            });
            setMyData(dcounts)
        }catch(err){
            console.log(err)
        }
    }
    const renderleaderboard =()=>{
        return myData.map((item, index) => (
            <tr key={index}>
                <td>{index + 1}</td>
                <td>{item.name}</td>
                <td>{item.thisgamescore}</td>
                <td>{item.score}</td>
            </tr>
        ))
    }
    useEffect(() => {
        let start;
        let starts
        const handleStartTime = () => {
            start = new Date();
            starts = moment().format('dddd  MMMM Do YYYY  h:mm:ss a');
        };
        const onClose = () => {
            const thisrid = sessionStorage.getItem("edureachumthisrid");
            const thisqueslist = sessionStorage.getItem("edureachumthisques");
            const thisid = sessionStorage.getItem("edureachumthisid");
            const ends = moment().format('dddd  MMMM Do YYYY  h:mm:ss a');
            const end = new Date();
            const timeDiff = end - start;
            const seconds = parseInt((timeDiff / 1000) % 60);
            const thisqq = {
                rid: thisrid,
                question: "Leaderboard",
                duration: seconds,
                timerin: starts,
                timerout: ends
            }
            let qq;
            if (thisqueslist) {
                const objectArray = JSON.parse(thisqueslist);
                qq = [...objectArray, thisqq];
            } else {
                qq = [thisqq]
            }
            const input = {
                id: thisid,
                questions: qq
            }
            API.graphql({
                query: updateLeaderboard,
                variables: {input}
            });
        }
        window.addEventListener('load', handleStartTime);
        window.addEventListener('beforeunload', onClose)
        window.addEventListener('blur', onClose);
        window.addEventListener('pagehide', onClose);
        return () => {
            window.removeEventListener('load', handleStartTime);
            window.removeEventListener('beforeunload', onClose);
            window.removeEventListener('blur', onClose);
            window.removeEventListener('pagehide', onClose);
        }
    }, []);
    return (
        <>
        <Helmet>
            <title>Leaderboard</title>
        </Helmet>
        <>
            <div className="leaderpageview">
                <div className="viewerleaderpageview">
            <div className="faviewleader">
                <h3 className="leadhead">Leaderboard</h3>
                <Table bordered size="sm">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Player</th>
                        <th>Last Game Score</th>
                        <th>Total Score</th>
                    </tr>
                    </thead>
                    <tbody className="leadertable">
                    {renderleaderboard()}
                    </tbody>
                </Table>
            </div>
                </div>
            </div>
        </>
            </>
    )
}

export default Leaderboard;
