import React, { useState } from "react";
import "../css/room.css";
import {Form, Input} from "element-react";
import {Button} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import {v4 as uuid} from "uuid";

function Vform() {
    const history = useNavigate();
    const { id } = useParams();
    const t = uuid()
    const [stateData, setStateData] = useState({
        meetname:"",
        meetemail:""
    });
    const [isValid, setIsValid] = useState(true);
    const handleSubmit2 = async event => {
        event.preventDefault();
        if(isValid === true) {
        try {
            const ssname = stateData.meetname;
            const ssemail = stateData.meetemail;
            localStorage.setItem("vreachumnewername",ssname);
            localStorage.setItem("vreachumneweremail", ssemail);
            history(`/groupleader/${id}/${t}`);
            sessionStorage.setItem("updatedusername", "1")
        }catch(err){
            console.log(err)
        }
        }
    }

    return (
        <>
            <div className="enterfirstyournameform">
                <br/>
                <br/>
                <h4 className="greenselfthis">Welcome!</h4>
                <br/>
                <p className="pxsize">Enter your email and a display name.<br/>
                    All games are anonymous.<br/>
                    Emails are used solely for reporting.</p>
                <Form className="market-header">
                    <Form.Item>
                        <Input
                            type="email"
                            value={stateData.meetemail}
                            onChange={meetemail => {
                                setStateData({
                                    ...stateData,
                                    meetemail: meetemail
                                });
                                const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                                const isValidemail = emailRegex.test(meetemail);
                                setIsValid(isValidemail);
                            }}
                            maxLength={100}
                            placeholder="Email"
                            className="craeteroomgame"
                        />
                        {!isValid && <p style={{color: '#d2196e'}}>Please enter a valid email address.</p>}
                    </Form.Item>
                    <Form.Item>
                        <Input
                            type="text"
                            value={stateData.meetname}
                            onChange={meetname => setStateData({
                                ...stateData,
                                meetname: meetname
                            })}
                            maxLength={50}
                            placeholder="Display Name"
                            className="craeteroomgame"
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            disabled={!stateData.meetemail || !stateData.meetname}
                            onClick={handleSubmit2}
                            variant="success"
                        >
                            &nbsp; &nbsp; &nbsp; Enter &nbsp; &nbsp; &nbsp;
                        </Button>
                    </Form.Item>
                </Form>
            </div>

        </>
    );
}

export default Vform;
